import React, { useState, useEffect } from "react";
import importdata from "../assets/importdata.png";
import bulkaction from "../assets/bulkaction.png";
import addnew from "../assets/addnew.png";
import search from "../assets/search.png";
import DataTable from "react-data-table-component";
import { Switch } from "antd";
import axios from "axios";
import { getToken } from "../utils/tokentUtils";
import { showFailedToastAlert } from "../utils/toastAlerts";
import { NavLink } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { BiCart } from "react-icons/bi";
import FormSkeleton from "./FormSkeleton";
import HandlingPagination from "./HandlingPagination";
import { apiBaseUrl } from "../utils/urls";
import parser from "html-react-parser";

import ViewMessageModalComponent from "./ViewMessageModalComponent";

const onChange = (checked: boolean) => {
  console.log(`switch to ${checked}`);
};

const formatDate = (dateString: string): string => {
  if (!dateString) return "";

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit format
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const Reports = () => {
  // ===========to get Reports Data ==============
  // const { getQueryData:Reports } = useGetQuery(
  //   `https://smsvendor.com/api/v1/admin/reports`,
  //   `https://smsvendor.com/api/v1/admin/reports`
  // );
  // console.log(Reports)

  const [Reports, setReports] = useState<any>(null);
  const [searchinput, setSearchInput] = useState<any>("");
  const [senderId, setSenderId] = useState<any>([]);
  const [dateFrom, setDateFrom] = useState<any>(null);
  const [customerType, setCustomerType] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);
  const [dateTo, setDateTo] = useState<any>(null);
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );
  const token = getToken();

  const [modal, setModal] = useState({
    open: false,
    id: "",
  });
  const modalHandle = (id: any) => {
    // console.log(id);
    setModal({ open: !modal?.open, id: id });
  };
  const columns: any = [
    {
      name: "Date",
      selector: (row) => (
        // <Moment format="  Do MMMM, YYYY  ">{row.created_at}</Moment>
        <div>{row?.created_at}</div>
      ),
    },
    {
      name: "Direction",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#07499A]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.send_by}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "User",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#07499A]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.user_id}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "Type",
      selector: (row) => (
        <div
          className={` capitalize font-normal text-[12px]   bg-[#F47C30]   text-white  px-3 py-1 rounded-[5px] w-full `}
        >
          {parser(`<div>
          ${row.sms_type}
          </div>
          `)}
        </div>
      ),
    },
    {
      name: "From",
      selector: (row) => row.from,
    },
    {
      name: "To",
      selector: (row) => row.to,
    },
    {
      name: "Cost",
      selector: (row) => row.cost,
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className={` ${
            row.status === "Delivered" ? "bg-green-500 " : "bg-red-500"
          } capitalize font-normal text-[12px]  text-white px-3 py-1 rounded-[5px] w-full `}
        >
          {row.status}
        </p>
      ),
    },
    {
      name: "Action",
      selector: (row) => {
        // console.log(row);
        return (
          <div className=" flex items-center gap-3 justify-center ">
            <div
              onClick={() => {
                modalHandle(row?.uid);
              }}
            >
              <svg
                fill="#F79236"
                width="19px"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8 5.5A2.59 2.59 0 0 0 5.33 8 2.59 2.59 0 0 0 8 10.5 2.59 2.59 0 0 0 10.67 8 2.59 2.59 0 0 0 8 5.5zm0 3.75A1.35 1.35 0 0 1 6.58 8 1.35 1.35 0 0 1 8 6.75 1.35 1.35 0 0 1 9.42 8 1.35 1.35 0 0 1 8 9.25z" />
                <path d="M8 2.5A8.11 8.11 0 0 0 0 8a8.11 8.11 0 0 0 8 5.5A8.11 8.11 0 0 0 16 8a8.11 8.11 0 0 0-8-5.5zm5.4 7.5A6.91 6.91 0 0 1 8 12.25 6.91 6.91 0 0 1 2.6 10a7.2 7.2 0 0 1-1.27-2A7.2 7.2 0 0 1 2.6 6 6.91 6.91 0 0 1 8 3.75 6.91 6.91 0 0 1 13.4 6a7.2 7.2 0 0 1 1.27 2 7.2 7.2 0 0 1-1.27 2z" />
              </svg>
            </div>
            <div
              // onClick={() => deleteMessage(row?.uid)}
              className="cursor-pointer"
            >
             
            </div>
          </div>
        );
      },
    },
  ];

  const fetchReports = (search = "")=>{
    try {
      const token = getToken();
      let data: any = {
        length: 10,
        start: 0,
        orderBy: "id",
        orderDir: "DESC",
        search: search ?? "",
        fromDate: null,
        is_reseller: customerType == 1 ? 1 : null,
        is_customer: customerType == 2 ? 0 : null,
        toDate: null,
      };

      if (dateFrom) data.fromDate = formatDate(dateFrom);
      if (dateTo) data.toDate = formatDate(dateTo);
      console.log("Here");

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/reports/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          if (data?.status === "success") {
            console.log(data.data.data);
            setReports(data?.data.data);
          } else {
            showFailedToastAlert(`Getting Reports error ${data?.message}`);
          }
        })
        .catch((error) => {
          console.log(`Getting Reports error ${error?.message}`);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    console.log("Im running");
    fetchReports()
    fetchSenderId();
  }, [dateFrom, dateTo, customerType]);

  useEffect(() => {
    if (searchinput.length < 3) return; // Only search after 3 characters

    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear previous timeout
    }

    const timeout = setTimeout(() => {
      fetchUsers();
    }, 2000); // 2-second delay

    setDebounceTimeout(timeout);

    return () => clearTimeout(timeout); // Cleanup on unmount or re-run
  }, [searchinput]);

  const fetchSenderId = ()=>{
    axios
    .post(`${apiBaseUrl}/admin/senderid/search`, {
      length: 10,
      start: 0,
      orderBy: "id",
      orderDir: "DESC",
      search: "",
      fromDate: null,
      toDate: null,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      if (response.data?.status === "success") {
        console.log(response.data.data.data);
        setSenderId(response.data.data.data)
      } else {
        showFailedToastAlert(`Getting users error ${response.data?.message}`);
      }
    })
    .catch((error) => {
      console.log(`Getting Reports error ${error?.message}`);
    });
  }

  
  // Function to convert JSON to CSV
  const convertToCSV = (json) => {
    if (!json.length) return "";

    // Extract only required fields: user name, from, to, sending_server, created_at (date), email, and cost
    const headers = "User Name,From,To,Sending Server,Date,Email,Cost\n";
    const rows = json
      .map((item) => {
        const userName = `${item.user.first_name} ${item.user.last_name}`; // User name
        const senderId = item.from || ""; // Sender ID
        const receiverId = item.to || ""; // Receiver ID
        const sendingServer = item.sending_server_id || ""; // Sending server
        const date = item.created_at ? new Date(item.created_at).toLocaleString() : ""; // Format Date
        const email = item.user.email || ""; // Email
        const cost = item.cost || ""; // Cost
        return `${userName},${senderId},${receiverId},${sendingServer},${date},${email},${cost}`;
      })
      .join("\n");

    return headers + rows;
  };

  // Function to download CSV
  const downloadExcel = () => {
    const csv = convertToCSV(Reports);
    if (!csv) return;

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "reports.csv"; // Name of the CSV file
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const fetchUsers = () => {
   
    const data = {
      search: searchinput,
    };

    axios
      .post(`${apiBaseUrl}/admin/reports/users`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data?.status === "success") {
          console.log(response.data.data.results);
          setUsers(response.data.data.results);
        } else {
          showFailedToastAlert(`Getting users error ${response.data?.message}`);
        }
      })
      .catch((error) => {
        console.log(`Getting Reports error ${error?.message}`);
      });

     
  };

  const searchUserByInput = (e)=>{
    if(e.key == "Enter"){
      fetchReports(searchinput);
    }

    return
  }

  // data that have been returned from pagination.
  const [dataForTable, setTable] = useState<any>();
  const getDataForTable = (paginatedValue) => {
    setTable(paginatedValue);
  };

  const data = dataForTable?.map((val) => ({
    id: val.uid,
    name: val.name,
    email: val.email,
    avatar: val.avatar,
    current: val.subscription,
    created_at: val.created_at,
    send_by: val.send_by,
    sms_type: val.sms_type,
    from: val.from,
    to: val.to,
    cost: val.cost,
    status: val.status,
    user_id: val.user_id,
    uid: val.uid,
  }));

  return (
    <div className="bg-white ">
      <ViewMessageModalComponent
        modalHandle={modalHandle}
        modalOpen={modal?.open}
        messageId={modal?.id}
      />
      <div className="mt-[16px] pt-[23px] pb-[12px] items-center flex md:flex-row gap-[20px] md:gap-0 flex-col justify-between md:pr-[16px] border-b-[1px] border-[#D9D9D9]">
        <div className="flex w-full justify-between items-center">
          <h2 className="text-[#5F6368] text-[20px] leading-[18.75px] font-bold pt-[4px] pl-[19.74px]">
            Reports
          </h2>
          <button onClick={downloadExcel} className="bg-green-500 text-sm  text-white py-2 px-5 rounded">
            Download Excel
          </button>
        </div>
      </div>
      <div className="flex flex-wrap justify-between pl-[37px] mt-[18px] pr-[14px] pb-[21px]">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Show
          </p>
          <select
            name="num"
            id="num"
            className=" border w-[56px] text-[#5F6368] text-[13px] leading-[15.23px] outline-none cursor-pointer"
          >
            <option value="10">10</option>
            <option value="9">9</option>
            <option value="8">8</option>
            <option value="7">7</option>
          </select>
          <p className="text-[#5F6368] text-[13px] leading-[15.23px] font-normal">
            Entries
          </p>
        </div>
        <div className="flex gap-4">
          <select
            name=""
            onChange={(e) => {
              fetchReports(e.currentTarget.value);
              console.log(e.currentTarget.value);
            }}
            className="bg-gray-100 py-1 px-2 rounded border"
            id=""
          >
            <option value="" defaultChecked>Sender Id</option>
           {
            senderId?.map((item, index)=>{
              return (<option key={index} value={item.sender_id}>{item.sender_id}</option>)
            })
           }
          </select>
          <select
            name=""
            onChange={(e) => {
              setCustomerType(e.currentTarget.value);
              console.log(e.currentTarget.value);
            }}
            className="bg-gray-100 py-1 px-2 rounded border"
            id=""
          >
            <option value="0">User Type</option>
            <option value="1">Reseller</option>
            <option value="2">Customer</option>
          </select>
        </div>
        <div className="flex gap-4">
          <input
            type="date"
            value={dateFrom}
            onChange={(e) => {
              setDateFrom(e.currentTarget.value);
              console.log(e.currentTarget.value);
            }}
            className="border p-2 bg-gray-100 rounded"
          />
          <input
            type="date"
            value={dateTo}
            onChange={(e) => setDateTo(e.currentTarget.value)}
            className="border p-2 rounded bg-gray-100"
          />
        </div>
        <div className="border relative border-[#D9D9D9] rounded-[2px] flex items-center mt-[14px] pr-[12px] w-[257px] justify-between">
          <input
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={searchUserByInput}
            type="text"
            value={searchinput}
            placeholder="Search"
            className="pt-[14px] pl-[14px] pb-[13px] outline-none placeholder:text-[#5F6368] leading-[14.52px] text-[12px] font-normal"
          />
          {users?.length > 0 ? (
            <div className="absolute top-full shadow-lg rounded left-0 z-50 bg-gray-50 w-full">
              <ul className="">
                {
                  users.map((item, index)=>{
                    return (
                      <li
                      key={index}
                        className="px-2 hover:bg-gray-200 py-2 border-t border-gray cursor-pointer"
                        onClick={(e) => {
                          setSearchInput(e.currentTarget.innerHTML);
                          fetchReports(e.currentTarget.innerHTML)
                          setUsers([])
                        }}
                      >
                        {item.term?.toLowerCase()}
                      </li>

                    )
                  })
                }
               
              </ul>
            </div>
          ) : null}

          <img src={search} alt="" className="cursor-pointer" />
        </div>
      </div>

      {Reports === null ? (
        <FormSkeleton titles={["Name", "Current Plan", "Status", "Action"]} />
      ) : (
        <DataTable
          columns={columns}
          data={data}
          selectableRows
          className="px-[10px] h-[550px]"
        />
      )}
      <HandlingPagination data={Reports} getDataForTable={getDataForTable} />
    </div>
  );
};

export default Reports;
